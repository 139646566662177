table#transactionDetailTable tbody tr td {
  padding: 8px 0px;
}

table#transactionDetailTable tbody tr td:last-child {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
