.slick-arrow {
  @apply rounded-full w-7 h-7 transition-all;
}

.slick-prev {
  @apply -left-10;
}

.slick-next {
  @apply -right-10;
}

.slick-arrow > * {
  stroke: #333;
}
